import Vue from 'vue'
import Router from 'vue-router'
import store from '../store';

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/dashboard/Dashboard')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')


// Auth
const LoginPage = () => import('@/views/auth/Login')

// Admins
const Admins = () => import('@/views/admins/Admins')
const Admin = () => import('@/views/admins/Admin')
const CreateAdmin = () => import('@/views/admins/Create')

// Articles
const Articles = () => import('@/views/articles/Articles')
const Article = () => import('@/views/articles/Article')
const CreateArticle = () => import('@/views/articles/Create')

// Questions
const Questions = () => import('@/views/questions/Questions')
const Question = () => import('@/views/questions/Question')

// Answers
const Answers = () => import('@/views/answers/Answers')
const Answer = () => import('@/views/answers/Answer')

// Categories
const Categories = () => import('@/views/categories/Categories')
const Category = () => import('@/views/categories/Category')
const CreateCategory = () => import('@/views/categories/Create')

// Services
const Services = () => import('@/views/services/Services')
const Service = () => import('@/views/services/Service')
const CreateService = () => import('@/views/services/Create')

// Countries
const Countries = () => import('@/views/countries/Countries')
const CreateCountry = () => import('@/views/countries/Create')

// States
const States = () => import('@/views/states/States')
const CreateState = () => import('@/views/states/Create')

// Clients
const Clients = () => import('@/views/clients/Clients')
const Client = () => import('@/views/clients/Client')

// Lawyers
const Lawyers = () => import('@/views/lawyers/Lawyers')
const Lawyer = () => import('@/views/lawyers/Lawyer')

// Bookings
const Bookings = () => import('@/views/bookings/Bookings')
const Booking = () => import('@/views/bookings/Booking')

// Consultations
const Consultations = () => import('@/views/consultations/Consultations')
const Consultation = () => import('@/views/consultations/Consultation')

// ServiceRequests
const ServiceRequests = () => import('@/views/serviceRequests/ServiceRequests')
const ServiceRequest = () => import('@/views/serviceRequests/ServiceRequest')


Vue.use(Router);

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      meta: {
        label: 'الرئيسية',
        requiresAuth: true
      },
      component: TheContainer,
      children: [
        // My Routes
        {
          path: 'dashboard',
          name: 'Dashboard',
          meta: {
            label: 'الاحصائيات'
          },
          component: Dashboard
        },
        
        // Clients
        {
          path: 'clients',
          meta: {
            label: 'العملاء'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Clients',
              meta: {
                label: 'المستخدم'
              },
              component: Clients
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل المستخدم'
              },
              name: 'Client',
              component: Client
            }
          ]
        },

        // Lawyers
        {
          path: 'lawyers',
          meta: {
            label: 'المحامون'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Lawyers',
              meta: {
                label: 'المحامي'
              },
              component: Lawyers
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل المحامي'
              },
              name: 'Lawyer',
              component: Lawyer
            }
          ]
        },
        
        // Bookings
        {
          path: 'bookings',
          meta: {
            label: 'الحجوزات'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Bookings',
              meta: {
                label: 'الحجز'
              },
              component: Bookings
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل الحجز'
              },
              name: 'Booking',
              component: Booking
            }
          ]
        },
        
        // Consultations
        {
          path: 'consultations',
          meta: {
            label: 'الاستشارات'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Consultations',
              meta: {
                label: 'الاستشارة'
              },
              component: Consultations
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل الاستشارة'
              },
              name: 'Consultation',
              component: Consultation
            }
          ]
        },
        
        // ServiceRequests
        {
          path: 'service-requests',
          meta: {
            label: 'طلبات الخدمة'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'ServiceRequests',
              meta: {
                label: 'طلب الخدمة'
              },
              component: ServiceRequests
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل طلب الخدمة'
              },
              name: 'ServiceRequest',
              component: ServiceRequest
            }
          ]
        },
        
        // Categories
        {
          path: 'categories',
          meta: {
            label: 'التصنيفات'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Categories',
              meta: {
                label: 'التصنيفات'
              },
              component: Categories
            },
            {
              path: 'create',
              name: 'Create Category',
              meta: {
                label: 'اضافة تصنيف'
              },
              component: CreateCategory
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل التصنيف'
              },
              name: 'Category',
              component: Category
            },
          ]
        },
        // Services
        {
          path: 'services',
          meta: {
            label: 'الخدمات'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Services',
              meta: {
                label: 'الخدمات'
              },
              component: Services
            },
            {
              path: 'create',
              name: 'Create Service',
              meta: {
                label: 'اضافة خدمة'
              },
              component: CreateService
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل الخدمة'
              },
              name: 'Service',
              component: Service
            },
          ]
        },

        // Articles
        {
          path: 'articles',
          meta: {
            label: 'المقالات'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Articles',
              meta: {
                label: 'المقالات'
              },
              component: Articles
            },
            {
              path: 'create',
              name: 'Create Article',
              meta: {
                label: 'اضافة مقال'
              },
              component: CreateArticle
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل المقال'
              },
              name: 'Article',
              component: Article
            },
          ]
        },

        // Questions
        {
          path: 'questions',
          meta: {
            label: 'الاسئلة'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Questions',
              meta: {
                label: 'الاسئلة'
              },
              component: Questions
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل السؤال'
              },
              name: 'Question',
              component: Question
            },
          ]
        },
        
        // Answers
        {
          path: 'answers',
          meta: {
            label: 'الاجابات'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Answers',
              meta: {
                label: 'الاجابات'
              },
              component: Answers
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل الاجابه'
              },
              name: 'Answer',
              component: Answer
            },
          ]
        },

        // Countries
        {
          path: 'countries',
          meta: {
            label: 'الدول'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Countries',
              meta: {
                label: 'الدول'
              },
              component: Countries
            },
            {
              path: 'create',
              name: 'Create Country',
              meta: {
                label: 'اضافة دولة'
              },
              component: CreateCountry
            }
          ]
        },

        // States
        {
          path: 'states',
          meta: {
            label: 'المحافظات'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'States',
              meta: {
                label: 'المحافظات'
              },
              component: States
            },
            {
              path: 'create',
              name: 'Create State',
              meta: {
                label: 'اضافة محافظة'
              },
              component: CreateState
            }
          ]
        },

        // Admins
        {
          path: 'admins',
          meta: {
            label: 'الادارة'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Admins',
              meta: {
                label: 'الاداري'
              },
              component: Admins
            },
            {
              path: 'create',
              name: 'Create Admin',
              meta: {
                label: 'اضافة اداري'
              },
              component: CreateAdmin
            },
            {
              path: ':id',
              meta: {
                label: 'تفاصيل الاداري'
              },
              name: 'Admin',
              component: Admin
            }
          ]
        },
      ]
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      name: 'Auth',
      component: {
        render(c) { return c('router-view') }
      },
      meta: {
        guest: true
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: LoginPage
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        }
      ]
    }
  ]
}


const router = new Router({
  routes: configRoutes(),
  // scrollBehavior() {
  //   document.getElementById('app').scrollIntoView();
  // },
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
  // const token = store.state.adminToken;
  if (to.matched.length == 0) {
    next({
      name: 'Page404',
    });
  }
  const token = localStorage.getItem('admin-token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token == null) {
      next({
        path: '/auth/login',
        params: {
          nextUrl: to.fullPath
        }
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (token == null) {
      next();
    } else {
      next({
        name: 'Home'
      });
    }
  } else {
    next()
  }
})


export default router