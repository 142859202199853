import 'core-js/stable'
import '@babel/polyfill'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axios from './utils/axios';
import Spinner from "./components/Spinner";
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';


Vue.config.performance = true

Vue.use(CoreuiVue);
Vue.use(VueFileAgent);
Vue.use(Vuelidate);
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    language: 'ar',
    region: 'EG',
    v: '3.26',
    libraries: 'places',
  },
  installComponents: true
});


Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$http = axios;
Vue.prototype.$swal = Swal;
Vue.prototype.$tinyUploadImage = process.env.VUE_APP_TINYMC_UPLOAD_URL;


Vue.component('Spinner', Spinner);
Vue.component('v-select', vSelect);


new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
