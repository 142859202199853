<template>
  <CCard class="align-items-center p-5 card-spinner">
        <CSpinner color="info" size="lg" style="width: 10rem; height: 10rem">
          Loading...
        </CSpinner>
      </CCard>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped>
.card-spinner {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  border: none;
  background-color: transparent;
}
</style>